import { Descriptions, Image } from 'antd';

export default function DevInfoContact() {
	return (
		<Descriptions bordered={true} layout="vertical">
			<Descriptions.Item label="微信">
				<Image src={'/wx-qr.png'} />
			</Descriptions.Item>
			<Descriptions.Item label="电话">13692111333</Descriptions.Item>
		</Descriptions>
	);
}
